<template>
  <div class="login-page">
    <div class="login-box">
      <!-- <div>{{ a }}</div> -->
      <van-tabs v-model="active" color="#319DE2">
        <van-tab title="账号密码登录">
          <van-form @submit="onLoginSubmit">
            <van-field
              v-model="username"
              center
              clearable
              name="账号"
              label="账号"
              placeholder="请输入登录账号"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="password"
              center
              clearable
              type="password"
              name="密码"
              label="密码"
              placeholder="请输入登录密码"
              :rules="[{ required: true }]"
            />
            <div style="margin: 16px;">
              <van-button class="login-btn" type="primary" block :loading="loading" native-type="submit">{{ loginBtnText }}</van-button>
            </div>
          </van-form>
        </van-tab>
        <van-tab title="手机号码登录">
          <van-form @submit="onMobileLoginSubmit" ref="mobileLogin">
            <van-field
              v-model="mobile"
              center
              clearable
              name="mobile"
              label="手机号码"
              placeholder="请输入手机号码"
              :rules="[{ required: true }]"
            />
            <van-cell-group inset>
              <van-field
                v-model="sms"
                center
                clearable
                label="验证码"
                placeholder="请输入短信验证码"
                :rules="[{ required: true }]"
              >
                <template #button>
                  <van-button
                    class="sms-btn"
                    size="small"
                    type="primary"
                    @click="getImgCaptcha"
                    native-type="button"
                    :disabled="state.smsSendBtn">{{ !state.smsSendBtn && '获取验证码' || (state.time+' s') }}</van-button>
                </template>
              </van-field>
            </van-cell-group>
            <div style="margin: 16px;">
              <van-button class="login-btn" type="primary" block :loading="loading" native-type="submit">{{ loginBtnText }}</van-button>
            </div>

            <div class="consent-text">
              <van-checkbox v-model="consent" shape="square">
                <div>我已阅读并同意<a @click="toPrivacyPage" class="privacy-link">《隐私政策》</a>，新用户直接注册试用测试数据。</div>
              </van-checkbox>
            </div>
          </van-form>
        </van-tab>
      </van-tabs>
    </div>
    <van-dialog
      v-model="captchaVisible"
      title="请输入图形验证码"
      show-cancel-button
      close-on-popstate
      class="captch-dialog"
      :before-close="getSms">
      <img :src="captchaImgSrc" />
      <a @click="getImgCaptcha" class="change-captcha-btn">看不清楚，换一张</a>
      <van-form ref="captchaField">
        <van-field
          v-model="captcha"
          name="captcha"
          placeholder="请输入图形验证码..."
          class="captcha-input"
          :rules="[{ required: true }]"/>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserLogin',
  components: {},
  data () {
    return {
      username: '',
      password: '',
      mobile: '',
      sms: '',
      loading: false,
      active: '',
      consent: false,
      captchaImgSrc: '',
      captchaVisible: false,
      captcha: '',
      uuid: '',
      state: {
        time: 60,
        smsSendBtn: false
      },
      a: navigator.userAgent.toLowerCase()
    }
  },
  computed: {
    ...mapState({
      loginPageActive: state => state.user.loginPageActive,
      deviceType: state => state.global.deviceType,
      inXbiApp: state => state.global.inXbiApp
    }),
    loginBtnText () {
      return this.loading ? '登录中…' : '登录'
    }
  },
  methods: {
    onLoginSubmit () {
      if (this.loading) return
      this.loading = true
      this.$api.user.loginPassword({
        username: this.username,
        password: this.password
      }).then(res => {
        const { access_token: accessToken, expires_in: expiresIn } = res
        this.$ls.set('accessToken', accessToken, (expiresIn - 1800) * 1000 + new Date().getTime())
        this.$store.commit('user/setAccessToken', accessToken)
        this.$eventBus.$emit('loginSuccess')
        this.loading = false
        this.$router.replace('/')
      }).catch(() => {
        this.loading = false
      })
    },
    onMobileLoginSubmit () {
      if (this.loading) return
      if (!this.consent) {
        this.$toast('请勾选我已阅读并同意《隐私政策》，新用户直接注册试用测试数据。')
        return
      }
      this.loading = true
      this.$api.user.loginMobile({
        mobile: this.mobile,
        captcha: this.sms
      }).then(res => {
        const { access_token: accessToken, expires_in: expiresIn } = res
        this.$ls.set('accessToken', accessToken, (expiresIn - 1800) * 1000 + new Date().getTime())
        this.$store.commit('user/setAccessToken', accessToken)
        this.$eventBus.$emit('loginSuccess')
        this.loading = false
        this.$router.replace('/')
      }).catch(() => {
        this.loading = false
      })
    },
    getImgCaptcha () {
      this.$refs.mobileLogin.validate('mobile').then(() => {
        this.$api.security.getImgCaptcha().then(res => {
          this.uuid = res.uuid
          this.captchaImgSrc = res.base64
          this.captcha = ''
          console.log(this)
          this.captchaVisible = true
          this.$refs.captchaField.resetValidation()
        }).catch(err => {
          console.log(err)
        })
      })
    },
    toPrivacyPage (e) {
      e.stopPropagation()
      this.$router.push(`/privacy`)
    },
    getSms (action, done) {
      if (action === 'confirm') {
        this.$refs.captchaField.validate('captcha').then(() => {
          const { state } = this
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          this.$api.security.sendCaptcha({
            mobile: this.mobile,
            uuid: this.uuid,
            captcha: this.captcha
          }).then(res => {
            this.$toast.success(res.message)
            this.captchaVisible = false
            done()
          }).catch(err => {
            console.log(err)
            state.time = 60
            state.smsSendBtn = false
            this.$toast.fail(((err.response || {}).data || {}).message || '请求出现错误，请稍后再试')
            this.getImgCaptcha()
            done(false)
          })
        }).catch(() => {
          return done(false)
        })
      } else {
        done()
      }
    }
    //   alertMess (s) {
    //     this.$toast.success(s)
    //   },
    //   alertErrMess (s) {
    //     this.$toast.fail(s)
    //   }
    // }
  },
  mounted () {
    if (this.inXbiApp && this.deviceType === 'ios') { // 苹果端app免登录
      this.username = 'admin'
      this.password = 'Huiying2022'
      this.onLoginSubmit()
    }
    //   window.alertMess = this.alertMess
    //   window.alertErrMess = this.alertErrMess
    //   if (window['XbiApp']) {
    //     window['XbiApp']['getPhoneNumber']()
    this.active = this.loginPageActive
  },
  watch: {
    active () {
      this.$store.commit('user/changeLoginPageActive', this.active)
    },
    captchaVisible (newVal) {
      if (newVal) {
        window.history.pushState({ title: '验证码页面', url: '#' }, '验证码页面', '#')
      } else if (history.state.title === '验证码页面') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less">
  .login-page {
    // background: #1e73e5;
    background-color: #ffffff;
    height: 100vh;
    .login-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-60%);
      width: 98vw;
      padding: 2em;
      background: #ffffff;
      border-radius: 0.5em;
      box-sizing: border-box;
      .van-tabs__wrap {
        .van-tab--active {
          .van-tab__text {
            font-weight: bold !important;
          }
        }
        .van-tab {
          .van-tab__text {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }
        }
      }
      .van-tabs__content {
        .van-field {
          display: block;
          margin-top: 20px;
            .van-field__label {
              display: block;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
            }
            .van-field__value {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #C2C2C3;
              line-height: 24px;
            }
        }
        .sms-btn {
          background-color: transparent;
          color: #000000;
          border: none;
          font-size: 16px;
        }
        .login-btn {
          margin-top: 60px;
          height: 49px;
          background: linear-gradient(90deg, #2680E7, #32C1EA);
          border-radius: 24px;

          font-size: 19px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }

        .consent-text {
          margin: 20px 16px 0;
          font-size: 12px;
          .privacy-link {
            color: #1890ff;
          }
        }
      }
    }
    .captch-dialog {
      .van-dialog__header {
        font-size: 18px;
      }
      .van-dialog__content {
        margin: 20px 16px 10px;
        .change-captcha-btn {
          color: #1890ff;
          font-size: 14px;
          margin-left: 5px;
        }
        .captcha-input {
          margin-top: 10px;
          padding: 0;
          font-size: 20px;
        }
      }
    }
  }
</style>
